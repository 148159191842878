import React, { useState, useEffect } from 'react'
import SettingForm from './View'

export default function MainSetting () {

  // get palette type have save on localstorage
  const [darkMode, setDarkMode] = useState(localStorage.getItem('paletteType')
    ? (localStorage.getItem('paletteType') !== 'light')
    : false)

  // get palette primary color have save on localstorage
  const [primaryColor, setPrimaryColor] = useState(localStorage.getItem('palettePrimaryColor')
    ? localStorage.getItem('palettePrimaryColor')
    : '#ff9100')

  // change and save palette type
  useEffect(() => {
    localStorage.setItem('paletteType', darkMode ? 'dark' : 'light')
    window.dispatchEvent(
      new CustomEvent('palette-type-changed', {
        detail: {
          storage: darkMode ? 'dark' : 'light'
        }
      })
    )
  }, [darkMode])

  // change and save palette primary color
  useEffect(() => {
    localStorage.setItem('palettePrimaryColor', primaryColor)
    window.dispatchEvent(
      new CustomEvent('palette-primary-color-changed', {
        detail: {
          storage: primaryColor
        }
      })
    )
  }, [primaryColor])

  // methods
  function handleChangePrimaryColor (newColor) {
    setPrimaryColor(`#${newColor.hex}`)
  }

  return (
    <SettingForm
      darkMode={darkMode}
      setDarkMode={setDarkMode}

      primaryColor={primaryColor}
      onChangePrimaryColor={handleChangePrimaryColor}
    />
  )
}
