const initialState = {
  authError: null
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        authError: null
      }

    case 'LOGIN_ERROR':
      return {
        ...state,
        authError: 'Mật khẩu hoặc tên đăng nhập không chính xác!'
      }

    case 'SIGNOUT':
      return state

    default:
      return state
  }
}
