import React from 'react'
import PropTypes from 'prop-types'
import QRCode from 'qrcode.react'

// MU stuff
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress'

//icon
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyles = makeStyles((theme) => ({
    container: {
      width: '100%',
      height: '90vh',
      margin: 0
    }
  }))

function QrCodeForm(props) {
    const classes = useStyles()
    const {
        valueQrCode,
        onRefresh,
        loading
    } = props

    return (
        <Grid className={classes.container} container justify="center" alignItems="center" >
            <Grid className='containerItem' item xl lg md sm xs component='div' align='center'>
            <QRCode
                size={250}
                includeMargin
                value={valueQrCode}
            />

            <div>
            <Button
                className="button"
                color="primary"
                startIcon={<RefreshIcon/>}
                disabled={loading}
                onClick={onRefresh}
                style={{ marginTop: '2%' }}
              >
                Làm mới
                {loading && (
                  <CircularProgress size={20} className="progress" />
                )}
              </Button>
            </div>
        </Grid>
        </Grid>
    )
}

QrCodeForm.propTypes = {
    valueQrCode: PropTypes.string,
    onRefresh:PropTypes.func,
    loading:PropTypes.bool
}

QrCodeForm.defaultProps = {
    valueQrCode: '',
    onRefresh: null,
    loading: false
}

export default QrCodeForm

