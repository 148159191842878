import React from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom'
import { isLoaded, isEmpty } from 'react-redux-firebase'

import LoadingPage from '../../views/pages/LoadingPage'
import MainLogin from '../../views/manage-login/Main'

// eslint-disable-next-line react/prop-types
function PrivateRoute ({ children, ...rest }) {
  const auth = useSelector(state => state.firebase.auth)

  if (!isLoaded(auth)) return <LoadingPage />

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoaded(auth) && isEmpty(auth) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}

export default function MainRouterLogin () {
  const match = useRouteMatch()

  return (
    <PrivateRoute>
      <Switch>
        <Route exact path={`${match.path}`} component={MainLogin} />
      </Switch>
    </PrivateRoute>
  )
}
