import React, { useState } from 'react'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux'

import SelectBrandForm from './View'

export default function MainSelectBrand () {
  const auth = useSelector(state => state.firebase.auth)
  const authData = useSelector(state => state.firestore.ordered.admin_auth && state.firestore.ordered.admin_auth[0])

  const [brandIdSelected, setBrandIdSelected] = useState('null')

  useFirestoreConnect([
    {
      collection: 'admin_auth',
      where: [['id', '==', `${auth.uid}`]]
    },
    {
      collection: 'brand',
      where: [['id', 'in', (authData && authData.brandIdList) ? authData.brandIdList : ['null']]]
    },
    {
      collection: 'brand',
      doc: brandIdSelected,
      subcollections: [{
        collection: 'store'
      }],
      storeAs: 'brand_selected_store'
    }
  ])

  const brandList = useSelector(state => state.firestore.ordered.brand)
  const storeList = useSelector(state => state.firestore.ordered.brand_selected_store)

  const [isShowSelectBrand, setIsShowSelectBrand] = useState(true)

  function handleSelectBrand (brand) {
    setBrandIdSelected(brand.id)
    setIsShowSelectBrand(false)
  }

  // store methods
  function handleSelectStore (store) {
    localStorage.setItem('brandId', brandIdSelected)
    localStorage.setItem('storeId', store.id)
    localStorage.setItem('tenantId', store.tenantId)
    window.location.href = '/'
  }

  return (
    <SelectBrandForm
      isShowSelectBrand={isShowSelectBrand}

      brandList={brandList}
      onSelectBrand={handleSelectBrand}

      storeList={storeList}
      onSelectStore={handleSelectStore}

    />
  )
}
