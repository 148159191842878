import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import PhoneNumberFormatCustom from '../../components/PhoneNumberFormatCustom'


// MU stuff
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'

// icon
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '3%',
    width: '100%',
    height: '100vh',
    margin: 0
  }
}))

function LoginForm(props) {
    const classes = useStyles()
  const { register, handleSubmit, errors } = useForm()

    const {
        account,
        onChangeValue,
        authError,
        onSubmitLogin,

        loading
    } = props

    const [isShowPassword, setisShowPassword] = useState(false)

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }


    return (
      <Grid className={classes.container} container justify="center" alignItems="center" >
        <Grid className='containerItem' item xl={5} lg={5} md={6} sm={10} xs={12} >
          <Card className='container' variant="outlined">
              <Typography className='textHeaderCard' variant="h5" component="div" align="center" >
                  Đăng nhập
              </Typography>

              <TextField
                className="textField"
                fullWidth
                required
                variant="outlined"
                label="Số điện thoại"
                name='phoneNumber'
                inputRef={register({
                  required: true
                })}
                InputProps={{
                  inputComponent: PhoneNumberFormatCustom,
                  value: account.phoneNumber ? account.phoneNumber : '',
                  onChange: onChangeValue
                }}
                error={!!errors.phoneNumber}
              />

              <FormControl fullWidth className="textField" variant="outlined">
                <InputLabel htmlFor="outlined-adornment-amount">Mật khẩu</InputLabel>
                <OutlinedInput
                  name='password'
                  type={isShowPassword ? 'text' : 'password'}
                  inputRef={register({
                    required: true
                  })}
                  error={!!errors.password}
                  value={account.password ? account.password : ''}
                  onChange={onChangeValue}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={setisShowPassword.bind(this, !isShowPassword)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {account.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>

              <Typography variant="caption" component="div" align="center" color="error" >
                {authError}
              </Typography>

              <Button
                variant="contained"
                color="primary"
                className="button container"
                fullWidth
                disabled={loading}
                onClick={handleSubmit(onSubmitLogin)}
                style={{ marginTop: '2%' }}
              >
                    Đăng nhập
                {loading && (
                  <CircularProgress size={20} className="progress" />
                )}
              </Button>
            </Card>
            </Grid>
        </Grid>
    )
}

LoginForm.propTypes = {
    account: PropTypes.object,
    onChangeValue: PropTypes.func,
    authError: PropTypes.string,
    onSubmitLogin: PropTypes.func,

    loading: PropTypes.bool
}

LoginForm.defaultProps = {
  account: {},
  onChangeValue: null,
  authError: '',
  onSubmitLogin: null,

  loading: false
}

export default LoginForm

