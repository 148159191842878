import React, { useState } from 'react'
import {  useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'

import QrCodeForm from "./View";

export default function MainQrCode() {

    useFirestoreConnect([
        {
          collection: 'brand',
          doc: localStorage.getItem('brandId') || 'null',
          subcollections: [{ collection: 'store' }],
          storeAs: 'store_brand_selected'
        }
      ])

    const storeInfo = useSelector(({ firestore: { data } }) => data.store_brand_selected && data.store_brand_selected[localStorage.getItem('storeId')])

    const [loading, setLoading] = useState(false)

    function handleGetStoreAddress () {
        if(!storeInfo) return ''
        return `${storeInfo.name}, ${storeInfo.address}`
      }

    function handleRefresh(){
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000);
    }

    function handleGetValueQrCode () {
        let result = ''
        const currentSeconds = Math.floor(new Date() / 1000)
        result = `${handleGetStoreAddress()}/time=${currentSeconds}`
        return result
      }

    return (
        <QrCodeForm 
            valueQrCode={handleGetValueQrCode()}
            onRefresh={handleRefresh}
            loading={loading}
        />
    )
}
