import React from 'react'
import PropTypes from 'prop-types'

// MU stuff
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '3%',
    width: '100%',
    height: '100vh',
    margin: 0
  }
}))

function SelectBrandForm (props) {
  const classes = useStyles()

  const {
    isShowSelectBrand,

    brandList,
    onSelectBrand,

    storeList,
    onSelectStore
  } = props

  return (
    <Grid className={classes.container} container justify="center" alignItems="center" >
      <Grid item xl={10} lg={10} md={12} sm={12} xs={12}>
        <Typography className='textHeaderCard' variant="h5" component="div" align="center" >
          {isShowSelectBrand ? 'Chọn thương hiệu' : 'Chọn cửa hàng' }
        </Typography>

        {isShowSelectBrand
          ? <Grid container justify="center" alignItems="center" spacing={2} >
            {brandList.map(tenant => (
              <Grid key={tenant.id} item xl={4} lg={4} md={6} sm={12} xs={12} >
                <Card className='container' variant="outlined" onClick={onSelectBrand.bind(this, tenant)}>
                  <CardActionArea >
                    <CardMedia
                      image={tenant.avatar.url}
                      style={{ height: '15em' }}
                    />
                    <Typography
                      variant="h6"
                      component="div"
                      align="center"
                    >
                      {tenant.name}
                    </Typography>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
          : <Grid container justify="center" alignItems="center" spacing={2} >
            {storeList.map(store => (
              <Grid key={store.id} item xl={4} lg={4} md={6} sm={12} xs={12} >
                <Card className='container' variant="outlined" onClick={onSelectStore.bind(this, store)}>
                  <CardActionArea >
                    <CardMedia
                      image={store.avatar.url}
                      style={{ height: '15em' }}
                    />
                    <Typography
                      variant="h6"
                      component="div"
                      align="center"
                    >
                      {store.name}
                    </Typography>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>}
      </Grid>
    </Grid>
  )
}

SelectBrandForm.propTypes = {
  isShowSelectBrand: PropTypes.bool,

  brandList: PropTypes.array,
  onSelectBrand: PropTypes.func,

  storeList: PropTypes.array,
  onSelectStore: PropTypes.func,

  onGoBack: PropTypes.func
}

SelectBrandForm.defaultProps = {
  isShowSelectBrand: false,

  brandList: [],
  onSelectBrand: null,

  storeList: [],
  onSelectStore: null
}

export default SelectBrandForm
