import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'

PhoneNumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired
}

function PhoneNumberFormatCustom (props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  )
}

export default PhoneNumberFormatCustom
