import { auth } from '../../services/fbConfig'

const signIn = (credentials) => {
  return async function (dispatch, getState, { getFirebase }) {
    await new Promise((resolve, reject) => {
      auth.signInWithEmailAndPassword(
        `${credentials.phoneNumber}@fnb.app`,
        credentials.password
      )
        .then(() => {
          dispatch({ type: 'LOGIN', payload: 'admin' })
          return resolve('ok')
        })
        .catch(err => {
          dispatch({ type: 'LOGIN_ERROR', err })
          return reject(err)
        })
    })
  }
}


const signOut = () => {
  return async function (dispatch, getState, { getFirebase }) {
    const firebase = getFirebase()
    firebase.auth().signOut()
      .then(() => {
        dispatch({ type: 'SIGNOUT' })
      })
  }
}

export {
  signIn,
  signOut
}
