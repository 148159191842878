import React from 'react'
import { useHistory } from 'react-router-dom'
import { signOut } from '../../../store/auth/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'

import ToolbarForm from "./View";

export default function MainNavBar() {
    const dispatch = useDispatch()
    const history = useHistory()

    const auth = useSelector(state => state.firebase.auth)

      // get data from firebase
    useFirestoreConnect([
        {
        collection: 'admin_auth',
        storeAs: 'admin_auth'
        }
    ])

    const account = useSelector(({ firestore: { data } }) => data.admin_auth && data.admin_auth[auth.uid])

    function handleSignOut () {
        const action = signOut()
        dispatch(action).then(() => {
          window.location.reload()
        })
      }

    return (
        <ToolbarForm 
          account={account}
          onSignOut={handleSignOut}
          history={history}
        />
    )
}
