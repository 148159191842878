import React, { useState } from 'react'
import { signIn } from '../../store/auth/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import LoginForm from './View'


export default function MainLogin() {
const history = useHistory()
  const dispatch = useDispatch()
  const authError = useSelector(store => store.auth.authError)

  // declare db
  const [account, setAccount] = useState({})
  const [loading, setLoading] = useState(false)

  // methods
  function handleChangeValue (e) {
    if (e.target.name === 'phoneNumber') {
      return setAccount({
        ...account,
        [e.target.name]: e.target.value.replace(/[ ]+/g, '')
      })
    }

    setAccount({
      ...account,
      [e.target.name]: e.target.value
    })
  }

  function handleSubmitLogin () {
    setLoading(true)
    const action = signIn(account)
    dispatch(action)
      .then(() => {
        history.push('/select-brand')
      })
      .catch(err => {
        console.error(err)
        setLoading(false)
      })
  }


    return (
        <LoginForm
        account={account}
        onChangeValue={handleChangeValue}
        authError={authError}
        onSubmitLogin={handleSubmitLogin}

        loading={loading}
        />
    )
}
