import React, { useState } from 'react'
import PropTypes from 'prop-types'

// MU stuff
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'

// icon
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import SettingsIcon from '@material-ui/icons/Settings'
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: localStorage.getItem('paletteType')
      ? (localStorage.getItem('paletteType') === 'light' ? 'white' : '#333')
      : 'white',
    boxShadow: 'none'
  },

  toolbar: {
    ...theme.mixins.toolbar
  },

  toolbarTitle: {
    flexGrow: 1
  }
}))


function ToolbarForm(props) {
    const classes = useStyles()

    const {
        account,
        onSignOut,
        history,
      } = props

    const [anchorElMenuAccount, setAnchorElMenuAccount] = useState(null)

    const ACCOUNT_ACTIONS = [
        {
        title: 'Cài đặt',
        icon: <SettingsIcon color='action' />,
        handleClick: function () {
            setAnchorElMenuAccount(null)
            history.push('/setting')
            }
        },
        {
        title: 'Đăng xuất',
        icon: <ExitToAppIcon color='action' />,
        handleClick: function () {
            setAnchorElMenuAccount(null)
            onSignOut()
            }
        }
    ]

    return (
        <AppBar
            position="fixed"
            className={classes.appBar}
            >
            <Toolbar variant="dense" className={classes.toolbar}>

                <div className={classes.toolbarTitle} >
                    <IconButton
                        size="small"
                        onClick={() => history.push('/')}
                        >
                        <CenterFocusWeakIcon />
                    </IconButton>
                </div>

                {/* account button */}
                <IconButton
                    size="small"
                    onClick={e => setAnchorElMenuAccount(e.currentTarget)}
                    >
                    {account &&
                        <Avatar
                        className={classes.iconSize}
                        alt="Avatar"
                        src={account.avatar ? account.avatar.url : ''}
                        />}
                </IconButton>

                {/* actions account */}
                <Menu
                anchorEl={anchorElMenuAccount}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                keepMounted
                open={Boolean(anchorElMenuAccount)}
                onClose={setAnchorElMenuAccount.bind(this, null)}
                >
                {ACCOUNT_ACTIONS.map((item, index) => (
                    <MenuItem dense key={index} onClick={item.handleClick} >
                    <ListItemIcon>
                        {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.title} />
                    </MenuItem>))}
                </Menu>

            </Toolbar>
            <Divider />
        </AppBar>
    )
}

ToolbarForm.propTypes = {
    account: PropTypes.object,
    onSignOut: PropTypes.func,
    history: PropTypes.object
}

ToolbarForm.defaultProps = {
    account: {},
    onSignOut: null,
    history: {},
}

export default ToolbarForm

