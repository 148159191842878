import React from 'react'
import { Redirect, Route } from 'react-router-dom'

// eslint-disable-next-line react/prop-types
export default function PrivateRouterForBrandIdSelected ({ children, ...rest }) {
  const isSelectBrand = !!(localStorage.getItem('brandId') && localStorage.getItem('storeId') && localStorage.getItem('tenantId'))

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isSelectBrand ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/select-brand',
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}
