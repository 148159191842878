import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'
import { firebaseReducer } from 'react-redux-firebase'
import { serviceWorkerReducer } from './service-worker/reducer'

import { authReducer } from './auth/reducer'
import { storeReducer } from './store/reducer'

const rootReducer = combineReducers({
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  serviceWorker: serviceWorkerReducer,

  auth: authReducer,

  store: storeReducer,
})

export default rootReducer