import firebase from 'firebase'
import 'firebase/auth'
import 'firebase/firestore'

// Initialize Firebase
var config = {
  apiKey: 'AIzaSyBbvg1AjHHPwOYqgi51lCnAc9Ax5frCA1o',
  authDomain: 'application-x-a2ba8.firebaseapp.com',
  databaseURL: 'https://application-x-a2ba8.firebaseio.com',
  projectId: 'application-x-a2ba8',
  storageBucket: 'application-x-a2ba8.appspot.com',
  messagingSenderId: '729273148736',
  appId: '1:729273148736:web:894a566f3f7741a5bf06a5',
  measurementId: 'G-Z3PEV8GS5Q'
}

firebase.initializeApp(config)
firebase.firestore()

const auth = firebase.auth()

export { firebase as default, auth }
