import React from 'react'
import PropTypes from 'prop-types'

import ThemeSetting from './features/Theme'

// Mu stuff
import Grid from '@material-ui/core/Grid'

function SettingForm (props) {
  const {
    darkMode,
    setDarkMode,

    primaryColor,
    onChangePrimaryColor
  } = props

  return (
    <Grid className='container' container justify='center' >
      <Grid className='containerItem' item xl={5} lg={5} md={8} sm={10} xs={12}>
        <div className='containerItem'>
          <ThemeSetting
            darkMode={darkMode}
            onChangeDarkMode={setDarkMode}
            primaryColor={primaryColor}
            onChangePrimaryColor={onChangePrimaryColor}
          />
        </div>
      </Grid>
    </Grid>
  )
}

SettingForm.propTypes = {
  darkMode: PropTypes.bool,
  setDarkMode: PropTypes.func,

  primaryColor: PropTypes.string,
  onChangePrimaryColor: PropTypes.func
}

SettingForm.defaultProps = {
  darkMode: false,
  setDarkMode: null,

  primaryColor: '',
  onChangePrimaryColor: null
}

export default SettingForm
