import React, { useState } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import RootRouter from './router/index'
import MainRouterLogin from "./router/login/index";
import MainSelectBrand from "./views/manage-select-brand/Main";
import MainNavBar from "./views/layout/nav-bar/Main";

import AuthIsLoaded from './router/loaded-components/AuthIsLoaded'
import PrivateRouterForBrandIdSelected from './router/private-router-components/BrandIdSelected'
import PrivateRouterForAuthLoggedIn from './router/private-router-components/AuthLoggedIn'

import { ThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  content: {
    flexGrow: 1
  },
  toolbar: {
    ...theme.mixins.toolbar
  }
}))


function App() {
    const classes = useStyles()

      // get palette type from local storage
  const [paletteType, setPaletteType] = useState(localStorage.getItem('paletteType')
    ? localStorage.getItem('paletteType')
    : 'light')

  // get palette primary color from local storage
  const [primaryColor, setPrimaryColor] = useState(localStorage.getItem('palettePrimaryColor')
    ? localStorage.getItem('palettePrimaryColor')
    : '#ff9100')

  // Where the original style was defined
  const theme = createMuiTheme({
    palette: {
      // color
      primary: {
        light: '#ff7961',
        main: primaryColor,
        dark: primaryColor,
        contrastText: '#fff'
      },
      secondary: {
        light: '#fff',
        main: '#000',
        dark: '#fff',
        contrastText: '#fff'
      },
      initial: {
        light: '#333',
        main: '#000',
        dark: primaryColor,
        contrastText: '#fff'
      },

      // dark or light theme
      type: paletteType
    }
  })

    // Resets the main type if there is a change
  window.addEventListener('palette-type-changed', event => {
    setPaletteType(event.detail.storage)
  })

  // Resets the main color if there is a change
  window.addEventListener('palette-primary-color-changed', event => {
    setPrimaryColor(event.detail.storage)
  })

  return (
  <ThemeProvider theme={theme}>

      {/* CssBaseline necessary for get style theme default app bar */}
      <CssBaseline />

      <AuthIsLoaded>

        <div className={classes.root}>
          <BrowserRouter>
            <Switch>

              {/* router is accessible without login  */}
              <Route path="/login" component={MainRouterLogin} />
              <Route path={'/select-brand'} component={MainSelectBrand} />

              {/* router is protected */}
              <PrivateRouterForAuthLoggedIn>
                <PrivateRouterForBrandIdSelected>

                    <MainNavBar />

                    <main className={classes.content}>
                      {/* space for toolbar */}
                      <div className={classes.toolbar} />
                      <RootRouter />
                    </main>

                </PrivateRouterForBrandIdSelected>
              </PrivateRouterForAuthLoggedIn>

            </Switch>
          </BrowserRouter>
        </div>

      </AuthIsLoaded>
    </ThemeProvider>
  );
}

export default App;
