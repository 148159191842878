const initialState = {
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null
}

export const serviceWorkerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SW_UPDATE':
      state.serviceWorkerUpdated = !state.serviceWorkerUpdated
      state.serviceWorkerRegistration = action.payload
      console.log('SW_UPDATE', state)
      return state

    default:
      return state
  }
}
