import React from 'react'
import PropTypes from 'prop-types'
import { ColorPicker } from 'material-ui-color'

// MU stuff
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'

// icon
import PaletteIcon from '@material-ui/icons/Palette'
import Brightness4Icon from '@material-ui/icons/Brightness4'

function ThemeSetting (props) {
  
  const { darkMode, onChangeDarkMode, primaryColor, onChangePrimaryColor } = props

  const PALETTE_OPTIONS = {
    red: '#ff0000',
    blue: '#0000ff',
    green: '#00ff00',
    yellow: 'yellow',
    cyan: 'cyan',
    lime: 'lime',
    gray: 'gray',
    orange: 'orange',
    purple: 'purple',
    black: 'black',
    white: 'white',
    pink: 'pink',
    darkblue: 'darkblue'
  }

  return (
    <div>
      <Typography variant="h6" >
        Giao diện
      </Typography>

      <Paper variant="outlined">
        <List style={{ padding: 0 }}>
          <ListItem button onClick={onChangeDarkMode.bind(this, !darkMode)}>
            <ListItemIcon>
              <Brightness4Icon />
            </ListItemIcon>

            <ListItemText primary='Giao diện tối' />

            <ListItemSecondaryAction>
              <Switch
                checked={darkMode}
                onChange={onChangeDarkMode.bind(this, !darkMode)}
                name="darkMode"
                color="primary"
              />
            </ListItemSecondaryAction>
          </ListItem>

          <Divider />

          {/* container select primary color */}
          <ListItem button onClick={() => document.getElementById('form-control-color').click()}>

            <ListItemIcon>
              <PaletteIcon />
            </ListItemIcon>

            <ListItemText primary='Màu chủ đạo' />

            <ListItemSecondaryAction>
              <FormControlLabel
                id='form-control-color'
                control={
                  <ColorPicker
                    value={primaryColor}
                    onChange={onChangePrimaryColor}
                    deferred
                    hideTextfield
                    palette={PALETTE_OPTIONS}
                  />}
                label={
                  <Typography variant="caption" color="textSecondary" >
                    {primaryColor}
                  </Typography>}
              />
            </ListItemSecondaryAction>

          </ListItem>
        </List>
      </Paper>
    </div>
  )
}

ThemeSetting.propTypes = {
  darkMode: PropTypes.bool,
  onChangeDarkMode: PropTypes.func,
  primaryColor: PropTypes.string,
  onChangePrimaryColor: PropTypes.func
}

ThemeSetting.defaultProps = {
  darkMode: false,
  onChangeDarkMode: null,
  primaryColor: '',
  onChangePrimaryColor: null
}

export default ThemeSetting
