import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import { ReactReduxFirebaseProvider, getFirebase } from 'react-redux-firebase'
import { createStore, compose, applyMiddleware } from 'redux'
import rootReducer from './store/index'
import thunk from 'redux-thunk'
import {
  reduxFirestore,
  getFirestore,
  createFirestoreInstance
} from 'redux-firestore'
import fbConfig from './services/fbConfig'
import firebase from 'firebase'

import { serviceWorkerUpdate } from './store/service-worker/actions'


// create root reducer
const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk.withExtraArgument({ getFirestore, getFirebase })),
    reduxFirestore(fbConfig)
  )
)

// get firebase config
const rrfProps = {
  firebase,
  config: fbConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

ReactDOM.render(
  // need for get root reducer
  <Provider store={store}>

    {/* need for get firebase db */}
    <ReactReduxFirebaseProvider {...rrfProps}>

      <App />

    </ReactReduxFirebaseProvider>

  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

const configuration = {
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      store.dispatch(serviceWorkerUpdate(registration))
    }
  }
}


serviceWorkerRegistration.register(configuration);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
